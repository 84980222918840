import React from 'react'
import { Link } from 'gatsby'
import { css } from 'emotion'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Label, Button, WhatNext } from '../components/global'

const spacer = css({
  marginBottom: '2em',
})

const Teachers = () => (
  <Layout>
    <SEO title="Teachers" />
    <div className="container">
      <h1>
        For <br /> Teachers
      </h1>
      <p className="boost">Automotive Technology - Diesel Technology - Technical Schools</p>
      <p>
        The vehicle service industry is in desperate need of technicians who can troubleshoot and
        repair electrical-electronic systems on today’s autos, trucks and heavy duty equipment.
      </p>
      <p>
        With our electrical training programs you will turn out students who quickly are recognized
        as the "go-to-tech" for electrical work.
      </p>
    </div>
    <div className="container-red bbw">
      <div className="container text-center">
        <Label>
          <p>Start Here</p>
        </Label>
        <h2>Electrical SHORTCUTS</h2>
        <p>
          Students will learn how to troubleshoot a battery in less than two minutes using just a
          DMM and a Current Clamp along with DMM test procedures for cranking and charging systems
          and a section on how to read schematic diagrams.
        </p>
        <div className={spacer}>
          <Button href="/products/shortcuts" text="Learn More"></Button>
        </div>
      </div>
    </div>
    <div className="container-red">
      <div className="container text-center">
        <Label>
          <p>Next</p>
        </Label>
        <h2>FIRST THINGS FIRST</h2>
        <p>
          Shows a student how to test the battery, two major ground circuits and the charging system
          performance "on-the-vehicle" in less than 5 minutes with a little practice.
        </p>
        <div className={spacer}>
          <Button href="/products/first-things-first" text={`Learn More`}></Button>
        </div>
      </div>
    </div>
    <div className="container container-last">
      <div className="text-center">
        <Label>
          <p>Then</p>
        </Label>
      </div>
      <h2>Hands-On Training</h2>
      <p>
        We’ve specially designed 5 circuit boards for guided hands-on repetition that develops
        electrical troubleshooting skills and maintains interest to keep the student involved.
      </p>
      <ol>
        <li>
          <Link to="/products/h-111a">Starter Kit H-111A (S)</Link>
        </li>
        <li>
          <Link to="/products/h-113">H-113(S) Troubleshooting DC Motor Circuits</Link>
        </li>
        <li>
          <Link to="/products/h-115">H-115(S), Relay Circuit Troubleshooting</Link>
        </li>
        <li>
          <Link to="/products/h-116">Wire Harness Troubleshooting Trainer H-116</Link>
        </li>
        <li>
          <Link to="/products/h-200">CAN Bus Troubleshooting Trainer H-200(S)</Link>
        </li>
      </ol>
      <p>
        What greater benefit can you be to your students than to prepare them for a meaningful
        career troubleshooting electrical-electronics in the vehicle service industry where
        electrical troubleshooting skill is in such great demand?
      </p>
      <p className="emphasis">
        Call us today to discuss how we can best help you
        <br />
        <strong>972-276-9642</strong>
      </p>
      <hr />
      <div className={`testimonial`}>
        <p>
          "I feel like I was finally able to get through to most of my students teaching them about
          electrical circuits and troubleshooting using your program."
          <span>Jeff C., WA</span>
        </p>
        <p>
          <a href="/">See other testimonials</a>
        </p>
      </div>
      <hr />
      <WhatNext />
    </div>
  </Layout>
)

export default Teachers
